import {Check, Close, ContentCopy} from "@mui/icons-material";
import React from "react";
import iscrizioniForm from '../data/formStructureAdecco.json'
import engagementForm from '../data/engagementFormStructure.json'
import checkinForm from '../data/checkinFormStructure.json'
import _ from 'lodash'
import {fieldTypes} from "./utils";
import axios from "axios";
import {API_URL, colorAreas} from "../config";
import authHeader from "./auth-header";
import CustomEngagementChip from "../components/CustomEngagementChip";
import {Box, Button, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";


export const forms = {
    ISCRIZIONI: {label: "Iscrizioni", index: 0},
    ENGAGEMENT: {label: "Dettaglio Engagement", index: 1},
    CHECKIN: {label: "Check-in", index: 2},
}

const columnsUrl = () => {
    return `${API_URL}config`
}

const getColumnsVisibilityModel = () => {
    //TODO: prendere colonne visibili dal server
    // return axios.get(url)
    let columnsVisibilityModel = {}
    getFieldsFromForm().forEach((field) => {
        columnsVisibilityModel = {
            ...columnsVisibilityModel,
            [field.id]: field.defaultVisible
        }
    })
    return columnsVisibilityModel
};

const getColumsPreferences = () => {
    return axios.get(columnsUrl(), { headers: authHeader() })
    //return columnsPreferences.columsToExport
};

const saveColumsToExport = (columns) => {
    return axios.post(columnsUrl(), {defaultExportColumns: columns}, {headers: authHeader()})
};

const saveDefaultVisibilityModel = (columns) => {
    return axios.post(columnsUrl(), {defaultVisibilityModel: columns}, {headers: authHeader()})
};

const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]

const getFieldsFromForm = (formIndex) => {
    let fields = []

    if(formIndex === forms.ENGAGEMENT.index) {
        return commonFields.concat(engagementForm.fields).concat([{
            "id": "engagementCompletedAt",
            "label": "Completato alle",
            "type": "datetime",
            "precompiled": true,
            "defaultVisible": true
        }])
    } else if(formIndex === forms.ISCRIZIONI.index) {
        iscrizioniForm.steps.forEach((step) => {
            fields = fields.concat(step.fields)
        })
        return fields
    } else if(formIndex === forms.CHECKIN.index) {
        return [{
            "id": "qrcodeUrl",
            "label": "QR Code",
            "type": "url",
            "defaultVisible": false,
            "precompiled": true,
            "condition": {
                "id": "partecipo",
                "value": "In presenza"
            }
        }].concat(commonFields.concat(checkinForm.fields))
    }
}

const getColumns = (formIndex) => {
    const cols = getFieldsFromForm(formIndex)?.map((field) => {
        let baseField = {
            field: field.id || field,
            headerName: field.label || field.id || (_.startCase(field)),
            flex: 1,
        }

        baseField = field.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return params.row[field.getter]?.[field.id]?._seconds ?
                    new Date(params.row[field.getter]?.[field.id]?._seconds * 1000)
                    : params.row[field.getter]?.[field.id]
            }
        } : {...baseField, valueGetter: (params) => {
                return params.row[field.id]?._seconds ?
                    new Date(params.row[field.id]?._seconds * 1000)
                    : params.row[field.id]
            }}

        const formType = _.find(fieldTypes, ['type', field.type])
        switch (formType) {
            case fieldTypes.DATE:
                return {
                    ...baseField,
                    type: 'date',
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleDateString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.DATE_TIME:
                return {
                    ...baseField,
                    type: 'date',
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.TIME:
                return {
                    ...baseField,
                    type: 'date', // TODO: capire
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleTimeString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.CHECK_BOX:
                return {
                    ...baseField,
                    flex: 0.6,
                    valueFormatter: (params) => {
                        if (params.value) {
                            return 'Sì';
                        } else return 'No';
                    },
                    renderCell: (params) => (params.value === true || params.value === '1')
                        ? <Check sx={{color: 'green'}}/>
                        : (params.value === false || params.value === '')
                            ? <Close color={'disabled'}/>
                            : '-'
                }
            case fieldTypes.URL:
                return {
                    ...baseField,
                    renderCell: (params) => params.value ?
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Tooltip title={"Copia link al qr"}>
                                <IconButton size={'small'} color={'primary'} onClick={() => navigator.clipboard.writeText(params.value)
                                    //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))
                                }>
                                    <ContentCopy fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Apri Qr Code in un\'altra finestra'}>
                                <Button href={params.value}
                                        target={'_blank'}
                                        sx={{backgroundColor: params.row.area ? colorAreas[params.row.area] : 'transparent'}}
                                        size={'small'}
                                        onClick={(event) => {event.stopPropagation()}}>
                                    <img src={params.value} style={{maxWidth: '30px'}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                        : ''
                }
            case fieldTypes.CUSTOM:
                return {
                    ...baseField,
                    valueFormatter: (params) => {
                        return !!params.value ? JSON.stringify(params.value) : "" // TODO
                    },
                    renderCell: (params) => !!params.value
                        ? <CustomEngagementChip field={field} values={params.value}/>
                        : null
                }
            default:
                return baseField
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumnsVisibilityModel,
    getColumns,
    getColumsPreferences,
    saveColumsToExport,
    saveDefaultVisibilityModel
};

export default ColumnsService;