import React, {useState} from "react";
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Badge,
    Box,
    Button,
    Card,
    Divider, ListItem,
    ListItemText,
    TextField, Tooltip
} from "@mui/material";
import {CheckCircle, Clear, FiberManualRecord} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import _ from 'lodash'
import {colorAreas} from "../config";
import IconButton from "@mui/material/IconButton";
import AuthService from "../services/auth.service";

const Areas = ({participants, checkinUser, resetCheckInUser}) => {
    const participantsFisici = _.filter(participants, ['partecipo', 'In presenza'])
    return (
        <Box>
            <Typography>Check-in
                totali: {_.filter(participants, ['checkin', true]).length} / {participantsFisici.length}</Typography>
            <Box mb={2} px={1} py={2} display={'flex'} flexWrap={"wrap"} justifyContent={'center'}
                 alignItems={'baseline'}>
                {Object.entries(colorAreas).map(([area, color], i) => {
                        const filteredParticipants = _.filter(participantsFisici, ['area', area])
                        const checkedInParticipants = _.filter(filteredParticipants, ['checkin', true])
                        return <Badge key={i} badgeContent={0} color={'primary'}>
                            <Box key={i} p={1} mb={2} ml={2} component={Card}
                                 sx={{background: color}}//sx={{background: 'rgba(153,152,164,0.16)'}}
                                 minWidth={'350px'} maxWidth={'350px'}>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <FiberManualRecord sx={{color: color || 'primary'}}/>
                                        <Typography gutterBottom variant={'h6'}
                                                    fontWeight={'lighter'}>Area <strong>{area}</strong></Typography>
                                    </Box>
                                    <Typography
                                        variant={'subtitle2'}>Check-in: {checkedInParticipants.length} / {filteredParticipants.length}</Typography>
                                </Box>
                                <Divider sx={{mb: 1}}/>
                                <Autocomplete
                                    size={'small'}
                                    renderInput={(params) => <TextField {...params} size={'small'}
                                                                        label="Partecipanti assegnati"/>}
                                    options={filteredParticipants}
                                    getOptionLabel={(option) => `${option.cognome} ${option.nome}`}
                                    renderOption={(props, option, {selected}) => (
                                        <ListItem
                                            key={option.id}
                                            secondaryAction={
                                                AuthService.getCurrentUserAdmin() ?
                                                    !option.checkin ?
                                                        <Tooltip title={'Check-in'}>
                                                            <IconButton
                                                                onClick={async () => {
                                                                    await checkinUser(option.id)
                                                                }}><CheckCircle
                                                                sx={{color: 'darkseagreen', fontSize: '1.5rem'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        : (option.checkin === true) ?
                                                            <Tooltip title={'Reset Check-in'}>
                                                                <IconButton
                                                                    onClick={async () => {
                                                                        await resetCheckInUser(option.id)
                                                                    }}><Clear sx={{color: 'red', fontSize: '1.5rem'}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            : null : null
                                            }
                                        >
                                            <ListItemText
                                                primary={option.nome + ' ' + option.cognome}
                                                secondary={option.updatedAt ?
                                                    `Check-in: ${new Date((option.updatedAt?._seconds * 1000)).toLocaleTimeString('it-IT')}`
                                                    : option.email
                                                }/>
                                        </ListItem>
                                    )}
                                />
                            </Box>
                        </Badge>
                    }
                )}
            </Box>
        </Box>
    );
}

export default Areas