import React from "react";
import {Chip, List, ListItem, ListItemText, Paper, Popper} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export const EngagementFields = ({list}) => {
    return <List dense>
        {
            Object.entries(list||[]).map(([key, value], i) => {
                return <ListItem key={i}>
                    <ListItemText primary={
                        <Chip label={value} color={"primary"} variant={'outlined'}/>
                    } secondary={key}/>
                </ListItem>
            })
        }
    </List>;
}

const CustomEngagementChip = ({field, values}) => {
    const anchorRef = React.useRef()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleToggleEngagement = (event) => {
        event.stopPropagation()
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const openAvvenimenti = Boolean(anchorEl);
    const id = openAvvenimenti ? 'simple-popper' : undefined;

    return <div ref={anchorRef}>
        <Chip aria-describedby={id}
              variant={'filled'} label={`${field.label}`} sx={{marginRight: 0.5}}
              onClick={handleToggleEngagement}
              icon={openAvvenimenti ? <ExpandLess/> : <ExpandMore/>}/>
        <Popper
            id={id}
            anchorEl={anchorEl}
            placement={'bottom-end'}
            open={openAvvenimenti}
            onClose={handleToggleEngagement}
        >
            <Paper elevation={4} sx={{minWidth: 'fit-content'}}>
                <EngagementFields list={values}/>
            </Paper>
        </Popper>
    </div>;
}

export default CustomEngagementChip