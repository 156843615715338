import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {colorAreas} from "../config";

const ConfirmCheckinDialog = ({open, handleClose, user}) => {
  return (
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
              {"Checkin avvenuto"}
          </DialogTitle>
          <DialogContent>
              <DialogContent id="alert-dialog-description">
                  <Typography variant={"h6"}>{user?.nome} {user?.cognome}</Typography>
                  <Chip label={`AREA ${user?.area}`} sx={{
                      fontWeight:'bold',
                      padding: 2,
                      fontSize:'1rem',
                      background: user?.area ? colorAreas[user?.area] : 'transparent',
                      //color: 'white'
                  }}/>
              </DialogContent>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} autoFocus>
                  OK
              </Button>
          </DialogActions>
      </Dialog>
  )
}

export default ConfirmCheckinDialog;
