import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {
    Backdrop,
    Box,
    Button,
    Chip,
    CircularProgress,
    Container, Dialog, DialogContent,
    DialogTitle,
    Snackbar,
    Tab,
    Tabs, Tooltip,
    Typography
} from "@mui/material";
import logo from '../assets/images/logo.png'
import {DataGrid, itIT} from '@mui/x-data-grid'
import UserService from "../services/user.service";
import CreateUser from "../components/CreateUser";
import {Alert} from "@mui/lab";
import CustomGridToolbar from "../components/CustomGridToolbar";
import {adeccoTheme} from "../theme/adeccoTheme";
import useAxios from "axios-hooks";
import ColumnsService, {forms} from "../services/columns.service";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";
import _ from 'lodash'
import {CheckCircle, Clear, Close} from "@mui/icons-material";
import engagementForm from '../data/engagementFormStructure.json'
import {API_URL, colorAreas} from "../config";
import axios from "axios";
import Areas from "../components/Areas";
import AuthService from "../services/auth.service";

const Home = () => {
    let navigate = useNavigate()

    const [activeTab, setActiveTab] = React.useState(AuthService.getCurrentUserAdmin() ? forms.CHECKIN.index : forms.ISCRIZIONI.index);
    const handleChangeActiveTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const [onlyCompleteFlag, setOnlyCompleteFlag] = useState(true)

    const [{data: allUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({complete: onlyCompleteFlag}), { method: "GET", useCache: false, manual: false }
    )

    const users: GridRowsProp = useMemo(() => {
        if(!allUsers)
            return []
        else return _.orderBy(allUsers.filter(function (o) {
            return !o.roles || o.test === true// && !o.test
        }), 'completedAt._seconds', 'desc')
    }, [allUsers])

    const usersPerForm = useMemo(() => {
        if (!users)
            return []

        const res = []
        res[forms.ENGAGEMENT.index] = _.orderBy(_.filter(users, ['engagementComplete', true]), 'engagementCompletedAt._seconds', 'desc')
        res[forms.ISCRIZIONI.index] = users
        res[forms.CHECKIN.index] = _.orderBy(_.filter(users, ['partecipo', 'In presenza']), ['checkin', 'updatedAt._seconds'], ['asc','desc'])
        return res
    }, [users])

    const [filteredUsers, reports]: GridRowsProp = useMemo(() => {
        if (!usersPerForm)
            return [[], null]

        const _filteredUsers = usersPerForm[activeTab]
        let _reports = {}

        if (forms.ENGAGEMENT.index === activeTab) {
            _.each(engagementForm.fields, (field) => {
                _.each(field.values, (value) => {
                    _reports[field.id] = {
                        ..._reports[field.id],
                        [value]: 0
                    }
                })
            })

            _.each(_filteredUsers, (user) => {

                if (user.engagement) {
                    _.each(engagementForm.fields, (field) => {
                        _reports[field.id][user.engagement[field.id]]++
                    })
                }
            })
        } else if (forms.CHECKIN.index === activeTab) {
            _reports = {
                'Partecipanti fisici': _.filter(_filteredUsers, ['partecipo', 'In presenza']).length,
                'Check-in effetttuati': _.filter(_filteredUsers, ['checkin', true]).length,
            }
        } else {
            _reports = {
                'Utenti totali': _filteredUsers.length,
                'Partecipanti fisici': _.filter(_filteredUsers, ['partecipo', 'In presenza']).length,
                'Partecipanti in streaming': _.filter(_filteredUsers, ['partecipo', 'In streaming']).length,
                'Check-in effetttuati': _.filter(_filteredUsers, ['checkin', true]).length,
            }
        }

        return [_filteredUsers, _reports]
    }, [usersPerForm, activeTab])

    const [{data: config, loading: loadingConfig}, refetchConfig] = useAxios(ColumnsService.columnsUrl(), {
        method: "GET", useCache: false
    })

    useEffect(() => {
        if(config)
            setColumnVisibilityModel(config.defaultVisibilityModel)
    }, [config])

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    const checkinUser = async (id) => {
        console.log("id:",id)
        await axios.get(API_URL + "participantsFisici/" + id + "/checkin")
            .then(() => fetchUsers())
            .catch(err => {
                console.log("err:",err)
                setError(err.response?.data?.error || "Errore")
            })
    };

    const resetCheckInUser = async (id) => {
        console.log("id:",id)
        await axios.get(API_URL + "participantsFisici/" + id + "/reset")
            .then(() => fetchUsers())
            .catch(err => setError(err.response))
    };

    const columns = useMemo(() => {
        const checkInCols = [
            {
                field: 'area',
                headerName: 'Area',
                flex: 1,
                renderCell: (params) => params.value ?
                    <Chip label={params.value}
                          sx={{fontWeight:'bold', background: colorAreas[params.value]}}/>
                    : '-'
            }
        ]

        if(activeTab === forms.CHECKIN.index) {
            if(AuthService.getCurrentUserAdmin()) {
                checkInCols.push({
                    field: "action",
                    headerName: "Action",
                    sortable: false,
                    flex: 1,
                    renderCell: (params) => {
                        const onClick = async (e) => {
                            e.stopPropagation(); // don't select this row after clicking
                            await checkinUser(params.id)
                        };
                        const onClickReset = async (e) => {
                            e.stopPropagation(); // don't select this row after clicking
                            await resetCheckInUser(params.id)
                        };

                        return !params.row.checkin ?
                            <Button variant={"outlined"}
                                    endIcon={<CheckCircle sx={{ color: 'darkseagreen', fontSize: '2.5rem' }} />}
                                    onClick={onClick}>Check-in</Button> :
                            <Button variant={"outlined"}
                                    endIcon={<Clear sx={{ color: 'red', fontSize: '2.5rem' }} />}
                                    onClick={onClickReset}>Reset Check-in</Button>;
                    }
                })
            }
            return ColumnsService.getColumns(activeTab).concat(checkInCols)
        } else if(activeTab === forms.ISCRIZIONI.index) {
            return checkInCols.concat(ColumnsService.getColumns(activeTab))
        }

        return ColumnsService.getColumns(activeTab)
    }, [activeTab])

    const [pageSize, setPageSize] = useState(10)

    const [openAreas, setOpenAreas] = React.useState(false);

    const handleSaveConfiguration = (newModel) => {
        setTimeout(function () {
            ColumnsService.saveDefaultVisibilityModel(newModel)
                .catch((err) => {
                    console.log("err:",err)
                    //setMessage({show: true, loading: false, text: "Configurazione non salvata", severity: "error"})
                })
                .finally(() => setColumnVisibilityModel(newModel))
        }, 200);
    }

    const [error, setError] = useState(null)
    function handleCloseError() {
        setError(null)
    }

    function handleAree() {
        setOpenAreas(true)
    }

    function handleCloseDialogAreas() {
        setOpenAreas(false)
    }

    return (
        <main>
            <Backdrop open={loadingUsers} sx={{zIndex: 10000}}/>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            <Box style={{background: adeccoTheme.palette.primary.main}}>
                <Container maxWidth={false}>
                    <div style={{display: 'flex', width: '100%', maxWidth: '180px'}}>
                        <img src={logo} width={'100%'}
                             style={{margin: '1rem 0', filter: 'brightness(0) invert(1)'}}/>
                    </div>
                </Container>
            </Box>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}} maxWidth={false}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                    <Typography variant={"h4"} sx={{textAlign: 'left'}}>Elenco Iscrizioni</Typography>
                    <Button onClick={handleAree} variant={'contained'}>Vedi aree</Button>
                    {/*<Button variant="contained" endIcon={<Add />} onClick={handleOpen}>
                        Aggiungi Utente
                    </Button>*/}
                    {/*<Tooltip arrow title={"Mostra solo le registrazioni completate"}>
                        <FormControlLabel
                            value="start"
                            componentsProps={{typography: {variant: 'subtitle2'}}}
                            control={<Switch
                                checked={onlyCompleteFlag}
                                onChange={() => setOnlyCompleteFlag(!onlyCompleteFlag)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Solo completate"
                            labelPlacement="start"
                        />
                    </Tooltip>*/}
                </Box>
                <div style={{display: 'flex', height: 670, marginTop: '1rem'}}>
                    <div style={{flexGrow: 1}}>
                        <Tabs value={activeTab} onChange={handleChangeActiveTab} aria-label="disabled tabs example">
                            {
                                Object.values(forms).map((form) => {
                                        if(form.index === forms.CHECKIN.index)
                                            return <Tab key={form.index}
                                                            label={`${form.label} (${_.filter(usersPerForm[form.index], ['checkin', true])?.length} / ${usersPerForm[form.index]?.length})`}/>
                                        return <Tab key={form.index}
                                                    label={`${form.label} (${usersPerForm[form.index]?.length})`}/>
                                    }
                                )
                            }
                        </Tabs>
                        <Box my={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'baseline'}>
                        {
                                Object.entries(reports || []).map(([key, value], i) => {
                                    if(activeTab === forms.ENGAGEMENT.index)
                                        return <Box key={i} mr={1} display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                                            <Chip size={'small'} variant={'outlined'} color={'primary'}
                                                  sx={{marginBottom: '4px'}}
                                                  label={_.startCase(key)}/>
                                            {
                                                Object.entries(value).map(([risp, counter], j) => {
                                                    return <Box mb={0.5} key={''+i+j} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                                                        <Chip size={'small'} variant={'filled'} color={'primary'}
                                                              sx={{color: 'white', marginRight: '2px'}}
                                                              label={`${counter || 0}`}/>
                                                        <Typography variant={'caption'} textAlign={'start'}>{risp}</Typography>
                                                    </Box>
                                                })
                                            }
                                        </Box>
                                    else return <Chip key={i} variant={'outlined'} color={'primary'} label={`${key}: ${value || 0}`} sx={{marginRight: 0.5}}/>
                                })
                        }
                        </Box>
                        <DataGrid
                            localeText={{
                                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                                toolbarExport: "Esporta colonne visibili"
                            }}
                            loading={loadingUsers || loadingConfig}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                handleSaveConfiguration(newModel)
                            }
                            disableSelectionOnClick
                            onRowClick={(params, event, details) => {
                                navigate("/user/" + params.row.id);
                                // TODO: Show row details
                            }}
                            components={{
                                Toolbar: () => {
                                    return <CustomGridToolbar/>
                                }
                            }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            columns={!loadingConfig ? columns : []}
                            rows={filteredUsers}
                            //checkboxSelection={true}
                        />
                    </div>
                </div>
            </Container>
            <Dialog open={openAreas} onClose={handleCloseDialogAreas} maxWidth={'md'}>
                <DialogTitle>Distribuzione partecipanti per area</DialogTitle>
                <DialogContent>
                    <Areas participants={filteredUsers || []} checkinUser={checkinUser} resetCheckInUser={resetCheckInUser}/>
                </DialogContent>
            </Dialog>
        </main>
    )
}

export default Home
