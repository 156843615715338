import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {IconButton} from "@mui/material";

export default function CheckinAppBar({handleDialogOpen}) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Check in
                    </Typography>
                    {<IconButton
                        size="large"
                        aria-label="cerca iscritto"
                        color="inherit"
                        onClick={handleDialogOpen}
                    >
                        <PersonSearchIcon />
                    </IconButton>}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
