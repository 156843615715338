import axios from "axios";
import {API_URL} from "../config";

const register = (mrMs, name, surname, email, country, company) => {
    return axios.post(API_URL + "register", {
        mrMs,
        name,
        surname,
        email,
        country,
        company
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "login", {
            username,
            password,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("admin", username.includes('plesh').toString());
            }
            return response.data
        });
};
const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("admin");
};
const getCurrentUserAdmin = () => {
    return localStorage.getItem("admin") ? JSON.parse(localStorage.getItem("admin")) : false;
};
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};
const getToken = () => {
    return JSON.parse(localStorage.getItem("user"))?.token;
};
const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    getCurrentUserAdmin,
    getToken
};
export default AuthService;
